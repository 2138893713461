@import "/src/styles/mixin";

.wrap {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  .text {
    @include font14Bold;
    letter-spacing: 0.01em;
  }

  .tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 8px 0;

    .icon {
      width: 20px;
      height: 24px;
    }

    .city {
      @include font14;
      font-weight: 600;
      letter-spacing: 0.01em;
    }
  }
}
