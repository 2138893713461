@import "/src/styles/mixin";

.wrap {
  position: relative;
  padding-left: 8px;
  margin-right: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 70%;
    transform: translateY(-50%);
    background-color: var(--grey-lines-color);
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}

.text {
  @include font14;
}
