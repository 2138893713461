@import "/src/styles/mixin";

.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 4;
  transform: translateY(-100%);
  transition: transform 0.2s ease;
  background-color: var(--white-color);

  &.isActive {
    transform: translateY(0);
    box-shadow: 0 20px 50px #1111111c;

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      transform: translateY(0);
      box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.09);
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      transform: translateY(-100px);
      box-shadow: unset;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    top: unset;
    bottom: 0;
    transform: translateY(0);
    box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.09);
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: unset;
    transform: translateY(100%);
    background-color: transparent;
  }
}

.content {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  background-color: var(--white-color);

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    gap: 12px;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--border-radius-small);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
  }
}

.title {
  @include font20;

  &Wrap {
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      max-width: 400px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.price {
  @include font12;
  text-decoration: line-through;
  font-weight: 500;

  &Discount {
    @include font24;
    white-space: nowrap;
  }

  &Wrap {
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      grid-column-gap: 12px;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 40px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
    gap: 16px;
    justify-content: space-between;
  }
}

.links {
  &Wrap {
    display: flex;
    gap: 16px;
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  &List {
    display: flex;
    gap: 16px;
  }

  &Link {
    @include font14;
    white-space: nowrap;
    border-bottom: 1px dashed var(--secondary-color);
  }
}
